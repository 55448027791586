import React, { useEffect, useState } from 'react'
import './AboutTrust.css'
import Gif1 from '../../../images/YearBanner1.gif'
import Gif2 from '../../../images/YearBanner22.gif'
import Gif3 from '../../../images/YearBanner3.gif'


const AboutTrust = () => {


    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div className='AboutTrustWrap'>

            <div className={`AboutTrustLeftCont ${isVisible ? 'animate-from-left' : ''}`}>
            </div>

            <div className={`AboutTrustRightCont ${isVisible ? 'animate-from-right' : ''}`}>

                <div className='YearBannerRightHeadText'>
                    Your Trusted Partner in Spare Parts
                </div>

                <div className='YearBannerRightMidText'>
                    * Top-Quality Products: We source only the best parts, including OEM (Original Equipment Manufacturer) and high-performance aftermarket parts, to ensure that your car gets exactly what it needs.
                    * Wide Selection: Whether you're repairing an everyday vehicle or customizing your ride, our diverse inventory has something for everyone. From engine components and brake parts to tires, suspension systems, and electrical accessories, we have you covered.
                    * Expert Advice: Our team consists of automotive experts with years of experience in the industry. We're here to provide helpful advice and guide you in choosing the right parts for your specific needs.
                    * Affordable Pricing: We believe that quality auto parts should be accessible to all. That's why we offer competitive prices, regular promotions, and a price match guarantee to ensure you're getting the best deal.
                    * Fast and Reliable Shipping: We understand that time is critical when it comes to car repairs. That's why we prioritize fast and reliable shipping to get your parts to you as quickly as possible.
                    * Customer Satisfaction: Your satisfaction is our top priority. With an easy-to-navigate website, hassle-free returns, and a responsive customer support team, we make sure your shopping experience is seamless and stress-free.
                </div>

               
            </div>
        </div>
    )
}

export default AboutTrust