import React, { useEffect, useState } from 'react'
import './Mission.css'
import { useInView } from 'react-intersection-observer';


const Mission = () => {


    const [isMissionVisible, setIsMissionVisible] = useState(false);

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 460);

    const { ref: MissionRef, inView: MissionInView } = useInView({
        triggerOnce: true,  // Trigger animation once when the element comes into view
        threshold: 0.5,  // Trigger when 50% of the element is in view
    });

    const textWithBreaks = (
        <>
            At Honour Auto, our mission is to provide our customers with high-quality, durable, and reliable auto parts that keep vehicles running smoothly. We’re not just in the business of selling parts – we’re here to support you through every repair, upgrade, or maintenance project you take on.
Our promise is to deliver products that you can trust, backed by knowledgeable staff and superior customer service. We aim to build lasting relationships with our customers, offering not only great products but the support you need for a successful auto repair experience.
        </>
    );

    const textWithoutBreaks =
        'At Honour Auto, our mission is to provide our customers with high-quality, durable, and reliable auto parts that keep vehicles running smoothly. We’re not just in the business of selling parts – we’re here to support you through every repair, upgrade, or maintenance project you take on.Our promise is to deliver products that you can trust, backed by knowledgeable staff and superior customer service. We aim to build lasting relationships with our customers, offering not only great products but the support you need for a successful auto repair experience.';



    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 460);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        if (MissionInView) {
            setIsMissionVisible(true)
        }
    }, [MissionInView])

    return (
        <div className='MissionWrap' ref={MissionRef}>

            <div className={`MissionLeftCont  ${isMissionVisible ? 'animate-from-left' : ''}`}>
            </div>

            <div className={`MissionRightCont ${isMissionVisible ? 'animate-from-right' : ''}`}>

                <div className='MissionRightHeadText'>
                    Our Mission
                </div>

                <div className='MissionRightMidText'>
                    {isSmallScreen ? textWithoutBreaks : textWithBreaks}
                </div>

                {/* <div className='YearBannerGifCont'>

                    <div className='YearBannerGifItem'>
                        <img className='YearBannerGif' src={Gif1} alt="" />
                        <div className='YearBannerGifText'>
                            25 Years Service <br /> Excellence
                        </div>
                    </div>

                    <div className='YearBannerGifItem'>
                        <img className='YearBannerGif' src={Gif3} alt="" />
                        <div className='YearBannerGifText'>Exceptional <br /> Customer <br /> Service</div>
                    </div>

                    <div className='YearBannerGifItem'>
                        <img className='YearBannerGif' src={Gif2} alt="" />
                        <div className='YearBannerGifText'>Fast & Reliable <br /> Delivery</div>

                    </div>

                </div> */}
            </div>
        </div>
    )
}

export default Mission