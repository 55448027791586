import React from 'react'
import './Footer.css'
import { CiLocationOn } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { FiPhone } from "react-icons/fi";

import { useNavigate } from 'react-router-dom'

const Footer = () => {



    const navigate = useNavigate()

    return (
        <div className='FooterWrap'>

            <div className='FooterTopCont'>
                <div className='FooterLeftCont'>

                    <div className='FooterContHeadText'>
                        Your premier destination for <br />
                        top-quality auto <br />
                        spare parts.
                    </div>

                    <div className='FooterContDescText'>
                        At Honour Auto, we are passionate about providing car owners, mechanics, and auto enthusiasts with the highest-quality auto parts and exceptional service.
                    </div>
                </div>

                <div>
                    <div className='FooterContHeadText'>Quick Links</div>
                    <div className='FooterContDescText Footer-Quick-Link-Container'>
                        <p className='Footer-quicklink-text' onClick={() => navigate('/')}>
                            Home
                        </p>
                        <p className='Footer-quicklink-text' onClick={() => navigate('/about')}>
                            About
                        </p>
                        <p className='Footer-quicklink-text' onClick={() => navigate('/products')}>
                            Products
                        </p>
                        <p className='Footer-quicklink-text' onClick={() => navigate('/contact')}>
                            Contact
                        </p>
                    </div>
                </div>

                <div className='FooterRightCont'>

                    <div className='FooterContHeadText'>Head Office</div>

                    <div className='FooterAddressCont'>
                        <CiLocationOn className='FooterAddressIcon' />
                        <div className='FooterContDescText'>
                            Honour Auto Spart Parts Trading L.L.C, <br />
                            Shop No:5, Rimal NAF 365 Building, <br />
                            Behind Deira Park Hotel, <br />
                            Deira, Dubai United Arab Emirates <br />
                            PO BOX NO - 187005
                        </div>
                    </div>
                    <div className='FooterAddressCont'>
                        <CiMail className='FooterAddressIcon' />
                        <div className='FooterMail-vertical'>
                            <div className='FooterContDescText'>
                                sales@honourauto.com
                            </div>
                            <div className='FooterContDescText'>
                                info@honourauto.com
                            </div>
                            <div className='FooterContDescText'>
                                honour22@eim.ae
                            </div>
                        </div>

                    </div>
                    <div className='FooterAddressCont'>
                        <FiPhone className='FooterAddressIcon' />
                        <div className='Footer-vertical'>
                            <div className='FooterContDescText'>
                                04-2841788
                            </div>
                            <div className='FooterContDescText'>
                                +971 55 860 6530
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className='FooterBottomCont'>

                <div className='FooterContDescText'>
                    All Rights Reserved | Honour Auto Spare Parts 2024
                </div>
            </div>
        </div>
    )
}

export default Footer