import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import './HistoryBanner.css'

const HistoryBanner = () => {

    const [isHistoryBannerVisible, setIsHistoryBannerVisible] = useState(false);

    const { ref: HistoryBannerRef, inView: HistoryBannerInView } = useInView({
        triggerOnce: true,  // Trigger animation once when the element comes into view
        threshold: 0.5,  // Trigger when 50% of the element is in view
    });

    useEffect(() => {
        if (HistoryBannerInView) {
            setIsHistoryBannerVisible(true)
        }
    }, [HistoryBannerInView])

    return (
        <div className='HistoryBannerWrap' ref={HistoryBannerRef}>

            <div className={`HistoryBannerLeftWrap ${isHistoryBannerVisible ? 'animate-slide-up' : ''}`}>

                <div className='HistoryBannerLeftCont'>
                    We offer a wide range of <br />
                    products to meet our <br />
                    customers diverse needs.
                </div>
            </div>

            <div className={`HistoryBannerRightWrap  ${isHistoryBannerVisible ? 'animate-slide-up' : ''}`}>

                <div className='HistoryBannerRightCont'>
                    Founded with a commitment to excellence, Honour Auto began with a vision to offer superior automotive parts at competitive prices. Over the years, we've grown into a trusted name in the auto parts industry, known for our dedication to quality, customer satisfaction, and innovation.
                    We work directly with some of the most reputable manufacturers worldwide to ensure that every part we offer meets the highest standards. From replacement parts and upgrades to performance parts and accessories, we stock a wide range of products for all vehicle makes and models.
                </div>
            </div>

        </div>
    )
}

export default HistoryBanner