import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import './AboutEnhance.css'

const AboutEnhance = () => {

    const [isHistoryBannerVisible, setIsHistoryBannerVisible] = useState(false);

    const { ref: HistoryBannerRef, inView: HistoryBannerInView } = useInView({
        triggerOnce: true,  // Trigger animation once when the element comes into view
        threshold: 0.5,  // Trigger when 50% of the element is in view
    });

    useEffect(() => {
        if (HistoryBannerInView) {
            setIsHistoryBannerVisible(true)
        }
    }, [HistoryBannerInView])

    return (
        <div className='AboutEnhanceWrap' ref={HistoryBannerRef}>

            <div className={`HistoryBannerLeftWrap ${isHistoryBannerVisible ? 'animate-slide-up' : ''}`}>

                <div className='HistoryBannerLeftCont AboutEnhanceLeft'>
                    Enhance your vehicle <br />
                    performance with trusted <br />
                    high-quality spare parts.
                </div>
            </div>

            <div className={`HistoryBannerRightWrap  ${isHistoryBannerVisible ? 'animate-slide-up' : ''}`}>

                <div className='HistoryBannerRightCont AboutEnhanceRightText'>
                Whether you’re fixing a daily driver, restoring a classic, or upgrading your car’s performance, Honour Auto is here to support you every step of the way. We invite you to browse our extensive catalog, reach out for expert advice, and experience the Honour Auto difference.
                </div>
            </div>

        </div>
    )
}

export default AboutEnhance