import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import './ContactMap.css'
import locationImg from '../../../images/locationImg.png'
import { useInView } from 'react-intersection-observer';


// Define a custom marker icon if needed
const customIcon = new L.Icon({
    iconUrl: locationImg,
    iconSize: [40, 60], // Replace with your icon URL
    iconSize: [25, 41],
    iconAnchor: [12, 41],
});

const ContactMap = () => {

    const [isMapVisible, setIsMapVisible] = useState(false);

    const { ref: MapRef, inView: MapInView } = useInView({
        triggerOnce: true,  // Trigger animation once when the element comes into view
        threshold: 0.4,  // Trigger when 50% of the element is in view
    });

    useEffect(() => {
        if (MapInView) {
            setIsMapVisible(true)
        }
    }, [MapInView])

    // Coordinates for the location (latitude, longitude)
    const position = [25.2711599, 55.3108874];


    return (
        <div className='ContactMapWrap' ref={MapRef}>

            <div className={`ContactMapCont ${isMapVisible ? 'animate-slide-up' : ''}`}>
               
               

                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3607.980536429789!2d55.30834957538425!3d25.27124017766341!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDE2JzE2LjUiTiA1NcKwMTgnMzkuMyJF!5e0!3m2!1sen!2sae!4v1733336584397!5m2!1sen!2sae" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
};


export default ContactMap