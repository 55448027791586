import React, { useEffect, useState } from 'react'
import './Vision.css'
import { useInView } from 'react-intersection-observer';


const Vision = () => {


    const [isVisionVisible, setIsVisionVisible] = useState(false);

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 460);

    const { ref: VisionRef, inView: VisionInView } = useInView({
        triggerOnce: true,  // Trigger animation once when the element comes into view
        threshold: 0.5,  // Trigger when 50% of the element is in view
    });

    const textWithBreaks = (
        <>
           At Honour Auto, our vision is to be the leading provider of high-quality, reliable auto parts and solutions, empowering vehicle owners and professionals worldwide to achieve peak performance, safety, and value. We aspire to create a future where every customer has access to the best parts, expert guidance, and a seamless experience, no matter where they are or what their vehicle needs.
        </>
    );

    const textWithoutBreaks =
        'At Honour Auto, our vision is to be the leading provider of high-quality, reliable auto parts and solutions, empowering vehicle owners and professionals worldwide to achieve peak performance, safety, and value. We aspire to create a future where every customer has access to the best parts, expert guidance, and a seamless experience, no matter where they are or what their vehicle needs.';



    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 460);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    useEffect(() => {
        if (VisionInView) {
            setIsVisionVisible(true)
        }
    }, [VisionInView])

    return (
        <div className='VisionWrap' ref={VisionRef}>

            <div className={`VisionRightCont ${isVisionVisible ? 'animate-from-left' : ''}`}>

                <div className='VisionRightHeadText'>
                    Our Vision
                </div>

                <div className='VisionRightMidText'>
                    {isSmallScreen ? textWithoutBreaks : textWithBreaks}
                </div>

            </div>

            <div className={`VisionLeftCont  ${isVisionVisible ? 'animate-from-right' : ''}`}>
            </div>
        </div>
    )
}

export default Vision