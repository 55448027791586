import React, { useEffect, useState } from 'react'
import './ContactForm.css'
import { useInView } from 'react-intersection-observer';

import axios from "axios";

const ContactForm = () => {

    const [isCallingAPI, setIsCallingAPI] = useState(false)

    const initialStateObject = {
        name: "",
        email: "",
        subject: "",
        message: ""
    }

    const [stateObject, setStateObject] = useState(initialStateObject)

    const [isFormVisible, setIsFormVisible] = useState(false);

    const { ref: FormRef, inView: FormInView } = useInView({
        triggerOnce: true,  // Trigger animation once when the element comes into view
        threshold: 0.4,  // Trigger when 50% of the element is in view
    });

    useEffect(() => {
        if (FormInView) {
            setIsFormVisible(true)
        }
    }, [FormInView])

    const handleInput = (e) => {
        setStateObject(prev => ({ ...prev, [e.target.name]: e.target.value }))

    }

    const validateInput = () => {
        if (stateObject.name?.length == 0 || stateObject.email?.length == 0 || stateObject.subject?.length == 0) {
            return false
        } else {
            return true
        }
    }

    const submitClicked = () => {

        if (validateInput()) {

            setIsCallingAPI(true)

            let apiUrl = "https://honourauto.com/honourautoapi/submit.php"


            let objectToSend = {
                name: stateObject.name,
                email: stateObject.email,
                subject: stateObject.subject,
                message: stateObject.message
            }

            axios.post(apiUrl, objectToSend, {
                headers: {
                    // Overwrite Axios's automatically set Content-Type
                    'Content-Type': 'application/json'
                }
            }).then((res) => {

                setIsCallingAPI(false)

                console.log("res", res)

                if (res.data.status == "success") {

                    alert(res.data.message)

                    setStateObject(initialStateObject)

                } else {
                    alert("something went wrong, please try later")
                }
            }).catch((err) => {
                setIsCallingAPI(false)
                console.log("err", err)
            })
        } else {
            alert("Please enter text in required fields")
        }

    }

    return (
        <div className='ContactFormWrap' ref={FormRef}>

            <div className={`ContactFormCont ${isFormVisible ? 'animate-slide-up' : ''}`}>

                <div className='FormField'>
                    <label htmlFor='name'>Your Name (required)</label>
                    <input value={stateObject.name} type='text' id='name' name='name' required onChange={(e) => handleInput(e)} />
                </div>


                <div className='FormField'>
                    <label htmlFor='email'>Your Email (required) </label>
                    <input value={stateObject.email} type='email' id='email' name='email' onChange={(e) => handleInput(e)} />
                </div>


                <div className='FormField'>
                    <label htmlFor='subject'>Subject (required)</label>
                    <input value={stateObject.subject} type='text' id='subject' name='subject' onChange={(e) => handleInput(e)} />
                </div>


                <div className='FormField'>
                    <label htmlFor='message'>Your Message (Optional)</label>
                    <textarea  value={stateObject.message} id='message' name='message' onChange={(e) => handleInput(e)} ></textarea>
                </div>

                <div className='FormSubmitCont'>
                    <div className='FormSubmitButton' onClick={() => submitClicked()}>
                        Submit
                    </div>
                </div>

            </div>

            {
                isCallingAPI &&
                <div className='Popup-outer'>
                    <div className='Popup-inner'>
                        Sending Enquiry, please wait...
                    </div>
                </div>
            }
        </div>

    )
}

export default ContactForm