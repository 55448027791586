import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import './YearBanner.css'
import Gif1 from '../../../images/YearBanner1.gif'
import Gif2 from '../../../images/YearBanner22.gif'
import Gif3 from '../../../images/YearBanner3.gif'
// import YearBannerLeft from '../../../images/yearBannerLeft.png'
import YearBannerLeft from '../../../images/HomeYearImg.jpeg'

const YearBanner = () => {

    const [isYearBannerVisible, setIsYearBannerVisible] = useState(false);

    const { ref: YearBannerRef, inView: YearBannerInView } = useInView({
        triggerOnce: true,  // Trigger animation once when the element comes into view
        threshold: 0.5,  // Trigger when 50% of the element is in view
    });

    useEffect(() => {
        if (YearBannerInView) {
            setIsYearBannerVisible(true)
        }
    }, [YearBannerInView])

    return (
        <div className='YearBannerWrap' ref={YearBannerRef}>

            <div className={`YearBannerLeftWrap ${isYearBannerVisible ? 'animate-from-left' : ''}`}>

                <img className='YearBannerLeftImg' src={YearBannerLeft} alt="" />
                {/* <div className='YearBannerLeftCont'>

                    <img className='YearBannerLeftImg' src={YearBannerLeft} alt="" />
                </div> */}
            </div>

            <div className={`YearBannerRightWrap ${isYearBannerVisible ? 'animate-from-right' : ''}`}>

                <div className='YearBannerRightHeadText'>
                    Professionals with 17 years of experience in the Automotive Industry
                </div>

                <div className='YearBannerRightMidText'>
                    Welcome to Honour Auto – Your Trusted Partner in Quality Auto Parts
                    At Honour Auto, we are passionate about providing car owners, mechanics, and auto enthusiasts with the highest-quality auto parts and exceptional service. Whether you're a DIY repairer or a professional, our goal is simple: to keep you and your vehicle on the road safely and reliably.
                </div>

                <div className='YearBannerGifCont'>

                    <div className='YearBannerGifItem'>

                        <div className='YearBannerGifText'>
                            Professionals with 17 years of experience in the Automotive Industry
                        </div>
                    </div>

                    <div className='YearBannerGifItem'>
                        {/* <img className='YearBannerGif' src={Gif3} alt="" /> */}
                        <div className='YearBannerGifText'>Exceptional <br /> Customer <br /> Service</div>
                    </div>

                    <div className='YearBannerGifItem'>
                        {/* <img className='YearBannerGif' src={Gif2} alt="" /> */}
                        <div className='YearBannerGifText'>Fast & Reliable <br /> Delivery</div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default YearBanner